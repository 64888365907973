<template>
  <v-dialog v-model="visibility" persistent>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <v-card style="height: 90svh" color="#151515">
      <div style="background-color: #1e1e1e">
        <v-row no-gutters align="center">
          <!-- <h3 style="color: #fff; margin-left: 20px">
            Розмір банеру 1180px x 400px
          </h3> -->
          <v-row no-gutters justify="end" style="padding: 10px">
            <div
              class="closeIcon"
              style="background-color: #fff; cursor: pointer"
              @click="$emit('close')"
            />
          </v-row>
        </v-row>
      </div>
      <div style="height: 76svh">
        <tui-image-editor
          ref="imageEditor"
          :include-ui="useDefaultUI"
          :options="options"
          style="width: 100% !important"
        ></tui-image-editor>
      </div>
      <v-row no-gutters>
        <div style="width: 450px; margin-left: calc(50svw - 250px)">
          <v-text-field
            outlined
            dense
            color="#E2E2E2"
            background-color="#fff"
            placeholder="Активне посилання"
            height="48px"
            style="border-radius: 10px"
            v-model="image_link"
          />
        </div>
        <v-row no-gutters justify="end" style="padding: 10px">
          <v-btn @click="saveImageAsHTML" style="text-transform: none"
            >Зберегти фото</v-btn
          >
        </v-row>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import { ImageEditor } from "@toast-ui/vue-image-editor";
import modalMixin from "../../../mixins/modalMixin";
import ErrorSnackbar from "../../UI/Snackbars/errorSnackbar.vue";
export default {
  components: {
    "tui-image-editor": ImageEditor,
    ErrorSnackbar,
  },
  mixins: [modalMixin],
  data: () => ({
    options: {
      includeUI: {
        // loadImage: {
        //   path: 'img/sampleImage.jpg',
        //   name: 'SampleImage'
        // },
        theme: {},
        //menu: ['shape', 'filter', 'text'],
        initMenu: "filter",
        menuBarPosition: "bottom",
      },
    },
    useDefaultUI: true,
    showErrorSnackbar: false,
    snackbarText: "",
    image_link: "",
  }),
  props: {
    isEdit: {
      require: false,
    },
    imageForEdit: {
      require: false,
    },
  },
  methods: {
    saveImageAsHTML() {
      const imageEditorInstance = this.$refs.imageEditor.invoke("toDataURL");
      if (this.getBase64Size(imageEditorInstance) / 1024 / 1024 < 1) {
        this.$emit("imageHTML", imageEditorInstance, this.image_link);
      } else {
        this.snackbarText = "Розмір зображення не повинен перевищувати 1 МБ";
        this.showErrorSnackbar = true;
        setTimeout(() => {
          this.showErrorSnackbar = false;
        }, 3000);
      }
    },
    getBase64Size(base64String) {
      const base64Data = base64String.split(",")[1] || base64String;
      const stringLength = base64Data.length;
      const sizeInBytes =
        (stringLength * 3) / 4 -
        (base64Data.endsWith("==") ? 2 : base64Data.endsWith("=") ? 1 : 0);
      return sizeInBytes;
    },
  },
};
</script>

<style>
@import url("https://uicdn.toast.com/tui-image-editor/latest/tui-image-editor.css");
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>
