import { render, staticRenderFns } from "./aboutUsComponent.vue?vue&type=template&id=9a803484&scoped=true"
import script from "./aboutUsComponent.vue?vue&type=script&lang=js"
export * from "./aboutUsComponent.vue?vue&type=script&lang=js"
import style0 from "./aboutUsComponent.vue?vue&type=style&index=0&id=9a803484&prod&scoped=true&lang=css"
import style1 from "./aboutUsComponent.vue?vue&type=style&index=1&id=9a803484&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a803484",
  null
  
)

export default component.exports