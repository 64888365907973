<template>
  <v-col class="px-0 py-0">
    <Loader v-if="showLoader" />
    <v-col class="px-0 py-0" v-else>
      <success-snackbar
        v-if="showSuccessSnackbar"
        :snackbarText="snackbarText"
      />
      <change-lang-form @setLang="setLang" />
      <div class="sectionBackground">
        <p class="sectionTitle">Банери</p>
        <p class="sectionText">
          Додайте банери, щоб зацікавити людей вашими пропозиціями
        </p>
        <div class="sectionLine" />
        <div class="swiper-container">
          <div
            class="iconBackground swiper-button-prev-banner"
            slot="button-prev"
          >
            <div class="swipeBtn" style="transform: rotate(180deg)" />
          </div>
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            style="width: calc(100% - 100px)"
            v-if="activeLang == 'ua'"
          >
            <swiper-slide
              v-for="(banner, index) in banners"
              :key="banner.id"
              :cssMode="true"
              :navigation="true"
              :pagination="true"
              :mousewheel="true"
              :keyboard="true"
              :modules="swiperOptions.modules"
            >
              <div
                v-if="hoverIndex == index && banner.html !== ''"
                class="transition-div show"
                @mouseleave="hoverIndex = null"
              >
                <v-row no-gutters justify="center">
                  <div class="iconBackground" @click="deleteBanner(banner)">
                    <div class="deleteIcon" />
                  </div>
                </v-row>
              </div>
              <div
                style="
                  border-radius: 10px;
                  border: 1px solid #e2e2e2;
                  background: #e7edf6;
                  width: 100%;
                  height: 300px;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  user-select: none;
                "
                @click="
                  (editedBanerIndex = index), (showImageEditorModal = true)
                "
              >
                <v-col
                  style="text-align: center"
                  v-if="!banner.path && !banner.html"
                >
                  <img src="@/assets/img/iconsSvg/imageIcon.svg" />
                  <p
                    style="
                      color: #1b1b1b;
                      font-family: 'MacPaw Fixel';
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-decoration: underline;
                      margin-top: 10px;
                    "
                  >
                    Завантажити банер
                  </p>
                </v-col>
                <img
                  v-else-if="banner.path"
                  :src="banner.path"
                  alt="image"
                  width="100%"
                  height="272px"
                  style="object-fit: cover"
                />
                <img
                  v-else-if="banner.html"
                  :src="banner.html"
                  width="100%"
                  height="400px"
                  @mousemove="hoverIndex = index"
                />
              </div> </swiper-slide
          ></swiper>
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            style="width: calc(100% - 100px)"
            v-if="activeLang == 'en'"
          >
            <swiper-slide
              v-for="(banner, index) in bannersEN"
              :key="banner.id"
              :cssMode="true"
              :navigation="true"
              :pagination="true"
              :mousewheel="true"
              :keyboard="true"
              :modules="swiperOptions.modules"
            >
              <div
                v-if="hoverIndex == index && banner.html !== ''"
                class="transition-div show"
                @mouseleave="hoverIndex = null"
              >
                <v-row no-gutters justify="center">
                  <div class="iconBackground" @click="deleteBanner(banner)">
                    <div class="deleteIcon" />
                  </div>
                </v-row>
              </div>
              <div
                style="
                  border-radius: 10px;
                  border: 1px solid #e2e2e2;
                  background: #e7edf6;
                  width: 100%;
                  height: 300px;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  user-select: none;
                "
                @click="
                  (editedBanerIndex = index), (showImageEditorModal = true)
                "
              >
                <v-col
                  style="text-align: center"
                  v-if="!banner.path && !banner.html"
                >
                  <img src="@/assets/img/iconsSvg/imageIcon.svg" />
                  <p
                    style="
                      color: #1b1b1b;
                      font-family: 'MacPaw Fixel';
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-decoration: underline;
                      margin-top: 10px;
                    "
                  >
                    Завантажити банер
                  </p>
                </v-col>
                <img
                  v-else-if="banner.path"
                  :src="banner.path"
                  alt="image"
                  width="100%"
                  height="272px"
                  style="object-fit: cover"
                />
                <img
                  v-else-if="banner.html"
                  :src="banner.html"
                  width="100%"
                  height="400px"
                  @mousemove="hoverIndex = index"
                />
              </div> </swiper-slide
          ></swiper>
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            style="width: calc(100% - 100px)"
            v-if="activeLang == 'pl'"
          >
            <swiper-slide
              v-for="(banner, index) in bannersPL"
              :key="banner.id"
              :cssMode="true"
              :navigation="true"
              :pagination="true"
              :mousewheel="true"
              :keyboard="true"
              :modules="swiperOptions.modules"
            >
              <div
                v-if="hoverIndex == index && banner.html !== ''"
                class="transition-div show"
                @mouseleave="hoverIndex = null"
              >
                <v-row no-gutters justify="center">
                  <div class="iconBackground" @click="deleteBanner(banner)">
                    <div class="deleteIcon" />
                  </div>
                </v-row>
              </div>
              <div
                style="
                  border-radius: 10px;
                  border: 1px solid #e2e2e2;
                  background: #e7edf6;
                  width: 100%;
                  height: 300px;
                  display: grid;
                  place-items: center;
                  cursor: pointer;
                  user-select: none;
                "
                @click="
                  (editedBanerIndex = index), (showImageEditorModal = true)
                "
              >
                <v-col
                  style="text-align: center"
                  v-if="!banner.path && !banner.html"
                >
                  <img src="@/assets/img/iconsSvg/imageIcon.svg" />
                  <p
                    style="
                      color: #1b1b1b;
                      font-family: 'MacPaw Fixel';
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-decoration: underline;
                      margin-top: 10px;
                    "
                  >
                    Завантажити банер
                  </p>
                </v-col>
                <img
                  v-else-if="banner.path"
                  :src="banner.path"
                  alt="image"
                  width="100%"
                  height="272px"
                  style="object-fit: cover"
                />
                <img
                  v-else-if="banner.html"
                  :src="banner.html"
                  width="100%"
                  height="400px"
                  @mousemove="hoverIndex = index"
                />
              </div> </swiper-slide
          ></swiper>
          <div
            class="iconBackground swiper-button-next-banner"
            slot="button-next"
          >
            <div class="swipeBtn" />
          </div>
        </div>
      </div>

      <!-- <v-btn @click="showImageEditorModal = true"></v-btn> -->
      <div class="sectionBackground" style="margin-top: 20px">
        <p class="sectionTitle">Опис</p>
        <div class="sectionLine" />
        <v-row no-gutters align="center">
          <v-col cols="4">
            <div
              style="
                border-radius: 10px;
                border: 1px solid #e2e2e2;
                background: #e7edf6;
                width: 300px;
                height: 272px;
                display: grid;
                place-items: center;
                cursor: pointer;
                user-select: none;
              "
              @click="showImageGalleryModal = true"
            >
              <v-col style="text-align: center" v-if="!image.path">
                <img src="@/assets/img/iconsSvg/imageIcon.svg" />
                <p
                  style="
                    color: #1b1b1b;
                    font-family: 'MacPaw Fixel';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: underline;
                    margin-top: 10px;
                  "
                >
                  Завантажити фото
                </p>
              </v-col>
              <img
                v-else
                :src="image.path"
                alt="image"
                width="300px"
                height="272px"
                style="object-fit: cover"
              />
            </div>
          </v-col>
          <v-col cols="8">
            <v-textarea
              placeholder="Придумайте, що б ви хотіли дізнатися про вашу компанію"
              dense
              outlined
              style="border-radius: 10px"
              height="100px"
              color="#E2E2E2"
              background-color="#FFF"
              v-model="descriptionModel"
              @input="isEditContent = true"
            />
            <v-row no-gutters align="center" style="margin-top: 20px">
              <v-col
                cols="4"
                :style="
                  index == 1
                    ? 'padding: 0px 10px;'
                    : index == 0
                    ? 'padding-right: 10px'
                    : 'padding-left: 10px'
                "
                v-for="(fact, index) in facts"
                :key="fact.id"
              >
                <v-text-field
                  outlined
                  dense
                  height="48px"
                  style="border-radius: 10px"
                  :label="`Факт ${index + 1} в цифрах`"
                  color="#E2E2E2"
                  background-color="#FFF"
                  v-model="fact.title[activeLang]"
                  type="number"
                  @input="fact.is_edited = true"
                />
                <v-text-field
                  outlined
                  dense
                  height="48px"
                  style="border-radius: 10px"
                  label="Опис факту 1"
                  color="#E2E2E2"
                  background-color="#FFF"
                  hide-details
                  v-model="fact.content[activeLang]"
                  @input="fact.is_edited = true"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sectionBackground" style="margin-top: 20px">
        <p class="sectionTitle">Відео</p>
        <p class="sectionText">
          Додайте відео, яке буде представляти вашу компанію
        </p>
        <div class="sectionLine" />
        <v-col cols="4" class="px-0 py-0">
          <v-text-field
            outlined
            dense
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            label="Посилання"
            placeholder="Введіть тут.."
            hide-details
            height="48px"
            v-model="youtubeVideo.value"
            @input="isEditYoutubeLink = true"
          />
        </v-col>
      </div>
      <v-btn
        width="100%"
        class="saveBtn"
        height="48px"
        color="#144FA9"
        :disabled="$v.youtubeVideo.$invalid"
        style="margin-bottom: 40px"
        @click="saveChanges"
        >Зберегти зміни</v-btn
      >
      <image-gallery-modal
        v-if="showImageGalleryModal"
        :visible="showImageGalleryModal"
        type="content"
        @chooseImage="chooseImage"
        @close="showImageGalleryModal = false"
      />
      <image-editor-modal
        v-if="showImageEditorModal"
        :visible="showImageEditorModal"
        :isEdit="isEditBanner"
        :imageForEdit="bannerForEdit"
        @imageHTML="setImage"
        @close="showImageEditorModal = false"
      />
    </v-col>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import settingsService from "../../../requests/Admin/settingsService";
import contentService from "../../../requests/Content/contentService";
import requestFormData from "../../../requests/requestFormData";
import ChangeLangForm from "../../UI/Forms/changeLangForm.vue";
import ImageGalleryModal from "../../UI/imageGalleryModal.vue";
import successSnackbar from "../../UI/Snackbars/successSnackbar.vue";
import Loader from "../../UI/Loader.vue";
import ImageEditorModal from "./imageEditorModal.vue";
import swiperOptionMixin from "../../../mixins/swiperOptionMixin";
import { Pagination, Navigation } from "vue-awesome-swiper";
import bannerService from "../../../requests/Content/bannerService";
export default {
  components: {
    successSnackbar,
    ImageGalleryModal,
    ChangeLangForm,
    Loader,
    ImageEditorModal,
  },
  mixins: [validationMixin, swiperOptionMixin],
  data: () => ({
    content: {
      description: {
        content: "",
      },
      description_en: {
        content: "",
      },
      description_pl: {
        content: "",
      },
    },
    image: {},
    youtubeVideo: { value: "" },
    facts: [],
    banners: [
      {
        id: 1,
        path: "",
        html: "",
        is_new: true,
      },
    ],
    bannersEN: [
      {
        id: 1,
        path: "",
        html: "",
        is_new: true,
      },
    ],
    bannersPL: [
      {
        id: 1,
        path: "",
        html: "",
        is_new: true,
      },
    ],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-banner",
        prevEl: ".swiper-button-prev-banner ",
      },
      modules: [Navigation, Pagination],
    },
    hoverIndex: null,
    showSuccessSnackbar: false,
    isEditContent: false,
    isEditFacts: false,
    isEditYoutubeLink: false,
    isEditBanner: false,
    bannerForEdit: "",
    showImageGalleryModal: false,
    showImageEditorModal: false,
    showLoader: true,
    snackbarText: "",
    activeLang: "ua",
    editedBanerIndex: null,
  }),
  validations: {
    description: {
      required,
    },
    youtubeVideo: {
      required,
    },
    facts: {
      $each: {
        key: {
          required,
        },
        value: {
          required,
        },
      },
    },
  },
  mounted() {
    this.getSettings();
    this.getContent();
    this.getBanners();
  },
  methods: {
    saveChanges() {
      this.saveSetting();
    },
    setLang(activeLang) {
      this.activeLang = activeLang;
    },
    async getContent() {
      await contentService.getContentForAdminBySection("Main",'').then((res) => {
        if (res.status == "Success") {
          let content = res.data?.[0];
          this.content = content;
          this.content.description = content?.translations?.find(
            (translate) => translate.lang == "ua"
          );
          this.content.description_en = content?.translations?.find(
            (translate) => translate.lang == "en"
          );
          this.content.description_pl = content?.translations?.find(
            (translate) => translate.lang == "pl"
          );
          this.image = content.images?.[0];
        }
      });
      await contentService.getContentForAdminBySection("Facts").then((res) => {
        if (res.status == "Success") {
          this.facts = [];
          res.data.forEach((fact) => {
            let fact_ua = fact.translations.find(
              (translate) => translate.lang == "ua"
            );
            let fact_en = fact.translations.find(
              (translate) => translate.lang == "en"
            );
            let fact_pl = fact.translations.find(
              (translate) => translate.lang == "pl"
            );
            this.facts.push({
              ...fact,
              content: {
                ua: fact_ua.content,
                en: fact_en.content,
                pl: fact_pl.content,
              },
              title: {
                ua: fact_ua.title,
                en: fact_en.title,
                pl: fact_pl.title,
              },
              is_edited: false,
            });
          });
        }
      });
    },
    async getSettings() {
      await settingsService.getSettingListForAdmin().then((res) => {
        if (res.status == "Success") {
          this.youtubeVideo = res.data.find(
            (setting) => setting.key == "youtube_link"
          );
        }
      });
    },
    async getBanners() {
      await bannerService.getBannerForAdmin().then((res) => {
        if (res.status == "Success") {
          this.banners =  res.data.filter((banner) => banner.lang == "ua").reverse();
          this.bannersEN = res.data.filter((banner) => banner.lang == "en").reverse();
          this.bannersPL = res.data.filter((banner) => banner.lang == "pl").reverse();
        }
      });
      this.showLoader = false;
    },
    async saveSetting() {
      if (this.isEditFacts) {
        this.facts.forEach(async (fact) => {
          if (fact.is_edited) {
            let ua_translation = fact.translations.find(
              (translate) => translate.lang == "ua"
            );
            let pl_translation = fact.translations.find(
              (translate) => translate.lang == "en"
            );
            let en_translation = fact.translations.find(
              (translate) => translate.lang == "pl"
            );
            let data = {
              is_popular: "No",
              published_at: fact.published_at,
              images: [],
              section: fact.section,
              translations: [
                {
                  id: ua_translation.id,
                  lang: "ua",
                  slug: ua_translation.slug,
                  title: fact.title.ua,
                  content: fact.content.ua,
                  section: ua_translation.section,
                },
                {
                  id: en_translation.id,
                  lang: "en",
                  slug: en_translation.slug,
                  title: fact.title.en,
                  content: fact.content.en,
                  section: en_translation.section,
                },
                {
                  id: pl_translation.id,
                  lang: "pl",
                  slug: pl_translation.slug,
                  title: fact.title.pl,
                  content: fact.content.pl,
                  section: pl_translation.section,
                },
              ],
              categories: [],
            };
            let form = requestFormData.jsonToFormData(data);
            await contentService.updateContent(fact.id, form).then((res) => {
              if (res.status == "Success") {
                this.showSuccessSnackbar = true;
                this.snackbarText = "Дані успішно оновлено";
                setTimeout(() => {
                  this.showSuccessSnackbar = false;
                }, 3000);
              }
            });
          }
        });
      }
      if (this.isEditYoutubeLink) {
        this.$v.youtubeVideo.$touch();
        if (!this.$v.youtubeVideo.$invalid) {
          let form = new FormData();
          form.append("type", this.youtubeVideo.type);
          form.append("key", this.youtubeVideo.key);
          form.append("value", this.youtubeVideo.value);
          form.append("can_delete", this.youtubeVideo.can_delete);
          form.append("is_hidden", this.youtubeVideo.is_hidden);
          await settingsService
            .updateSetting(this.youtubeVideo.id, form)
            .then((res) => {
              if (res.status == "Success") {
                this.showSuccessSnackbar = true;
                this.isEditYoutubeLink = false;
                this.snackbarText = "Дані успішно оновлено";
                setTimeout(() => {
                  this.showSuccessSnackbar = false;
                }, 3000);
              }
            });
        }
      }
      if (this.isEditContent) {
        let ua_translation = this.content.translations.find(
          (translate) => translate.lang == "ua"
        );
        let pl_translation = this.content.translations.find(
          (translate) => translate.lang == "en"
        );
        let en_translation = this.content.translations.find(
          (translate) => translate.lang == "pl"
        );
        let data = {
          is_popular: "No",
          published_at: this.content.published_at,
          images: [this.image.id],
          section: this.content.section,
          translations: [
            {
              id: ua_translation.id,
              lang: "ua",
              slug: ua_translation.slug,
              title: ua_translation.title,
              content: ua_translation.content,
              section: ua_translation.section,
            },
            {
              id: en_translation.id,
              lang: "en",
              slug: en_translation.slug,
              title: en_translation.title,
              content: en_translation.content,
              section: en_translation.section,
            },
            {
              id: pl_translation.id,
              lang: "pl",
              slug: pl_translation.slug,
              title: pl_translation.title,
              content: pl_translation.content,
              section: pl_translation.section,
            },
          ],
          categories: [],
        };
        let form = requestFormData.jsonToFormData(data);
        await contentService
          .updateContent(this.content.id, form)
          .then((res) => {
            if (res.status == "Success") {
              this.showSuccessSnackbar = true;
              this.isEditYoutubeLink = false;
              this.snackbarText = "Дані успішно оновлено";
              setTimeout(() => {
                this.showSuccessSnackbar = false;
              }, 3000);
            }
          });
      }
      this.banners.forEach(async (banner, index) => {
        if (banner.is_new && banner.html !== "") {
          let form = new FormData();
          form.append("slider_id", 1);
          form.append("order", index + 1);
          form.append("lang", "ua");
          form.append("html", banner.html);
          form.append("link", banner.link);
          await bannerService.createBanner(form).then((res) => {
            if (res.status == "Success") {
              this.showSuccessSnackbar = true;
              this.snackbarText = "Фото успішно додані";
              setTimeout(() => {
                this.showSuccessSnackbar = false;
              }, 3000);
            }
          });
        }
      });
      this.bannersEN.forEach(async (banner, index) => {
        if (banner.is_new && banner.html !== "") {
          let form = new FormData();
          form.append("slider_id", 1);
          form.append("order", index + 1);
          form.append("lang", "en");
          form.append("html", banner.html);
          form.append("link", banner.link);
          await bannerService.createBanner(form).then((res) => {
            if (res.status == "Success") {
              this.showSuccessSnackbar = true;
              this.snackbarText = "Фото успішно додані";
              setTimeout(() => {
                this.showSuccessSnackbar = false;
              }, 3000);
            }
          });
        }
      });
      this.bannersPL.forEach(async (banner, index) => {
        if (banner.is_new && banner.html !== "") {
          let form = new FormData();
          form.append("slider_id", 1);
          form.append("order", index + 1);
          form.append("lang", "pl");
          form.append("html", banner.html);
          form.append("link", banner.link);
          await bannerService.createBanner(form).then((res) => {
            if (res.status == "Success") {
              this.showSuccessSnackbar = true;
              this.snackbarText = "Фото успішно додані";
              setTimeout(() => {
                this.showSuccessSnackbar = false;
              }, 3000);
            }
          });
        }
      });
    },
    chooseImage(image) {
      this.image = image;
      this.showImageGalleryModal = false;
      this.isEditContent = true;
    },
    setImage(base64, link) {
      this.showImageEditorModal = false;
      if (this.activeLang == "ua") {
        this.banners[this.editedBanerIndex].html = base64;
        this.banners[this.editedBanerIndex].link = link;
      }
      if (this.activeLang == "en") {
        this.bannersEN[this.editedBanerIndex].html = base64;
        this.bannersEN[this.editedBanerIndex].link = link;
      }
      if (this.activeLang == "pl") {
        this.bannersPL[this.editedBanerIndex].html = base64;
        this.bannersPL[this.editedBanerIndex].link = link;
      }
    },
    async deleteBanner(banner) {
      if (banner.is_new) {
        this.banners = this.banners.filter((ban) => ban.id !== banner.id);
      } else {
        await bannerService.deleteBanner(banner.id).then(() => {
          this.banners = this.banners.filter((ban) => ban.id !== banner.id);
        });
      }
    },
  },
  computed: {
    descriptionModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.content.description.content;
          }
          case "en": {
            return this.content.description_en.content;
          }
          case "pl": {
            return this.content.description_pl.content;
          }
        }
        return this.content.description.content;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.content.description.content = val;
            }
            break;
          case "en":
            {
              this.content.description_en.content = val;
            }
            break;
          case "pl":
            {
              this.content.description_pl.content = val;
            }
            break;
        }
      },
    },
  },
  watch: {
    facts: {
      deep: true,
      handler() {
        this.isEditFacts = true;
      },
    },
    banners: {
      deep: true,
      handler() {
        let index = this.banners.findIndex((banner) => banner.html == "");
        if (index == -1) {
          this.banners.push({
            id: new Date().getTime(),
            path: "",
            html: "",
            is_new: true,
          });
        }
      },
    },
    bannersEN: {
      deep: true,
      handler() {
        let index = this.bannersEN.findIndex((banner) => banner.html == "");
        if (index == -1) {
          this.bannersEN.push({
            id: new Date().getTime(),
            path: "",
            html: "",
            is_new: true,
          });
        }
      },
    },
    bannersPL: {
      deep: true,
      handler() {
        let index = this.bannersPL.findIndex((banner) => banner.html == "");
        if (index == -1) {
          this.bannersPL.push({
            id: new Date().getTime(),
            path: "",
            html: "",
            is_new: true,
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.saveBtn {
  margin-top: 40px;
  border-radius: 10px;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: none;
}
.saveBtn:hover {
  background-color: #00318b !important;
}
.swiper-container {
  width: 100%;
  max-width: 75svw;
  display: flex;
  align-items: center;
}
.iconBackground:hover {
  background: #00318b;
}
.iconBackground:hover .swipeBtn {
  background-color: #fafafa;
}
.swipeBtn {
  width: 10px;
  height: 18px;
  background-color: #1b1b1bcc;
  -webkit-mask: url("../../../assets/img/iconsSvg/swiperRightIcon.svg")
    no-repeat center;
  mask: url("../../../assets/img/iconsSvg/swiperRightIcon.svg") no-repeat center;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.transition-div {
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: grid;
  place-items: center;
  animation: fadeInOut 0.3s ease-in-out;
  opacity: 0;
  border-radius: 10px;
}

.transition-div.show {
  opacity: 1;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>